import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'ranked-safe-area-top, ranked-safe-area-bottom, ranked-safe-area',
  template: ``,
  styleUrls: ['./safe-area.component.scss'],
})
export class SafeAreaComponent implements OnInit {
  @Input() @HostBinding('class') public color: 'primary' | 'secondary' | 'light' | 'dark';

  constructor(private element: ElementRef<HTMLElement>) {}

  private changeStatusBar(style: Style, backgroundColor: string): void {
    StatusBar.setStyle({ style });

    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: backgroundColor });
    }
  }

  public ngOnInit(): void {
    if (Capacitor.isNativePlatform() && this.element.nativeElement.tagName.endsWith('TOP')) {
      switch (this.color) {
        case 'primary':
          this.changeStatusBar(Style.Dark, 'A01D26');
          break;
        case 'secondary':
          this.changeStatusBar(Style.Light, '7E8C8C');
          break;
        case 'light':
          this.changeStatusBar(Style.Light, 'F2F2ED');
          break;
        case 'dark':
          this.changeStatusBar(Style.Dark, '1F2329');
          break;
      }
    }
  }
}
