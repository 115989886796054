import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NavbarIcon } from '../../model';

@Component({
  selector: 'ranked-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() public title: string;
  @Input() public icon: NavbarIcon = NavbarIcon.BACK;
  @Input() public iconTap: () => void = () => {
    this.location.back();
  };

  constructor(private location: Location) {}

  public onIconTapped(): void {
    this.iconTap();
  }
}
