import { Injectable } from '@angular/core';
import { dispatch, select, StoreService } from '@ngxp/store-service';
import { AppStarted } from '../state/settings.actions';
import { SettingsState } from '../state/settings.reducer';
import { getDeviceId } from '../state/settings.selectors';

@Injectable()
export class SettingsStoreService extends StoreService<SettingsState> {
  public appStarted = dispatch(AppStarted);

  public getDeviceId = select(getDeviceId);
}
