import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { AppStarted, DeviceIdGenerated, DeviceIdLoaded, GenerateDeviceId, LoadDeviceId, LoadDeviceIdFailed } from './settings.actions';

export enum AppSettings {
  DEVICE_ID_KEY = 'deviceId',
}

@Injectable()
export class SettingsEffects {
  loadDeviceIdOnAppInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppStarted),
      map(() => LoadDeviceId()),
    ),
  );

  loadDeviceIdFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDeviceId),
      map(() => {
        const savedDeviceId = localStorage.getItem(AppSettings.DEVICE_ID_KEY);
        return savedDeviceId ? DeviceIdLoaded({ deviceId: savedDeviceId }) : LoadDeviceIdFailed();
      }),
    ),
  );

  generateDevideIdIfMissing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDeviceIdFailed),
      map(() => GenerateDeviceId()),
    ),
  );

  generateDeviceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateDeviceId),
      map(() => DeviceIdGenerated({ deviceId: uuid.v4() })),
    ),
  );

  saveDeviceIdInAppSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceIdGenerated),
        tap(({ deviceId }) => {
          localStorage.setItem(AppSettings.DEVICE_ID_KEY, deviceId);
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions) {}
}
