import { createAction, props } from '@ngrx/store';

export const AppStarted = createAction('[Settings AppComponent] App Started');

export const LoadDeviceId = createAction('[Settings Effect] Load Device Id');

export const DeviceIdLoaded = createAction('[Settings Effect] Device Id Loaded', props<{ deviceId: string }>());

export const LoadDeviceIdFailed = createAction('[Settings Effect] Load Device Id Failed');

export const GenerateDeviceId = createAction('[Settings Effect] Generate Device Id');

export const DeviceIdGenerated = createAction('[Settings Effect] Device Id Generated', props<{ deviceId: string }>());
