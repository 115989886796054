import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor(private languageService: LanguageService) {}

  private addLanguageToFilename(filename: string): string {
    const extensionIndex = filename.lastIndexOf('.');
    return filename.substring(0, extensionIndex) + '_' + this.languageService.currentLang + filename.substring(extensionIndex);
  }

  public localizeImages(): void {
    const images = document.querySelectorAll('img.localized');

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const baseSrc = image.getAttribute('baseSrc');

      if (baseSrc !== null) {
        image.setAttribute('src', this.addLanguageToFilename(baseSrc));
      }
    }
  }
}
