<div class="attack-row">
  <div class="bar side left"></div>
  <img class="puppet" src="/assets/images/puppet2.svg" />
  <div class="bar middle"></div>
  <img class="puppet" src="/assets/images/puppet2.svg" />
  <div class="bar middle"></div>
  <img class="puppet" src="/assets/images/puppet2.svg" />
  <div class="bar side right"></div>
</div>

<div class="ball">
  <div class="ball-texture"></div>
</div>
