import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { UserFeedbackState, USERFEEDBACK_FEATURE_KEY } from './user-feedback.reducer';

export const getUserFeedbackState = createFeatureSelector<UserFeedbackState>(USERFEEDBACK_FEATURE_KEY);

export const getDisplayLoadingSpinner = createSelector(getUserFeedbackState, (state: UserFeedbackState) => state.loadingOrigins.length > 0);

export const getRecentMessage = createSelector(getUserFeedbackState, (state: UserFeedbackState) => state.messages[0]);

export const getMessageOutcomeById = (id: string): MemoizedSelector<object, unknown, DefaultProjectorFn<unknown>> =>
  createSelector(getUserFeedbackState, (state: UserFeedbackState) => state.messageOutcomes[id]);
