import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentType, ENVIRONMENT_TOKEN } from '@ranked/settings';

enum VerificationStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

@Component({
  selector: 'ranked-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent {
  private readonly VERIFICATION_CODE_PARAM = 'secret';
  private readonly VERIFICATION_EMAIL_PARAM = 'email';

  public verificationStatus$: BehaviorSubject<VerificationStatus>;

  constructor(activatedRoute: ActivatedRoute, @Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType, private http: HttpClient) {
    const verificationEmail = activatedRoute.snapshot.queryParamMap.get(this.VERIFICATION_EMAIL_PARAM);
    const verificationCode = activatedRoute.snapshot.queryParamMap.get(this.VERIFICATION_CODE_PARAM);

    this.verificationStatus$ = new BehaviorSubject(VerificationStatus.PENDING);
    this.verifyEmail(verificationEmail, verificationCode);
  }

  private verifyEmail(email: string | null, verificationCode: string | null): void {
    const url = `${this.environment.baseUrl}/accounts/verify`;
    const body = { emailAddress: email, verificationSecret: verificationCode };

    this.http.post<void>(url, body).subscribe(
      () => this.verificationStatus$.next(VerificationStatus.SUCCESS),
      () => this.verificationStatus$.next(VerificationStatus.ERROR),
    );
  }
}
