<h2 class="r-headline r-primary-color">{{ 'home.newsletter.title' | translate }}</h2>

<p class="r-fade-in-animation">
  {{ 'home.newsletter.description' | translate }}
</p>

<form
  ngNoForm
  class="subscription r-fade-in-animation"
  action="https://mailings.holisticon.de/f/10814-160234/wcs/"
  method="post"
  target="_blank"
>
  <ranked-input-enhancer class="mail-input">
    <input
      rankedInput
      placeholder="{{ 'home.newsletter.form.email-hint' | translate }}"
      pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
      type="email"
      name="email"
      autocomplete="email"
      [(ngModel)]="email"
      #emailInput="ngModel"
    />
  </ranked-input-enhancer>

  <input
    ranked-checkbox
    class="privacy-checkbox"
    type="checkbox"
    name="privacy-policy"
    id="{{ privacyCheckboxId }}"
    required
    [(ngModel)]="privacyPolicyAccepted"
  />
  <label for="{{ privacyCheckboxId }}"
    >{{ 'home.newsletter.form.privacy-prefix' | translate }}
    <a href="/privacy">{{ 'home.newsletter.form.privacy-link-text' | translate }}</a>
    {{ 'home.newsletter.form.privacy-suffix' | translate }}</label
  >

  <button
    ranked-button
    [disabled]="!(email.length > 0 && emailInput.valid && privacyPolicyAccepted)"
    size="small"
    text="{{ 'home.newsletter.form.button-label' | translate }}"
    type="submit"
  ></button>
</form>
