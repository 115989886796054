<div class="splash">
  <img src="assets/images/splash_minimum.svg" />
</div>

<ranked-header class="title-text r-fade-in-animation" [linkToHome]="false" [small]="(smallHeader$ | async) ?? false"></ranked-header>

<div class="store-links r-hide">
  <a href="" target="_blank">
    <img class="playstore" src="assets/images/Play_Store_Badge.png" />
  </a>
  <a href="" target="_blank">
    <img class="appstore" src="assets/images/App_Store_Badge.png" />
  </a>
</div>

<div class="scroll-indicator-container">
  <div class="scroll-indicator">
    <div class="arrow" (click)="scrollToContent()"></div>
  </div>
</div>

<div class="sections">
  <ranked-home-newsletter></ranked-home-newsletter>

  <ranked-home-features></ranked-home-features>

  <ranked-home-demo></ranked-home-demo>

  <ranked-home-team></ranked-home-team>

  <ranked-home-why></ranked-home-why>

  <ranked-home-newsletter></ranked-home-newsletter>
</div>

<!-- <div class="newsletter">
    <div class="r-headline r-primary-color r-blur-background title">{{ 'home.newsletter.headline' | translate }}</div>

    <div class="block description r-fade-in-animation">
      <p>{{ 'home.newsletter.promise.description-1' | translate }}</p>
    </div>

    <div class="block subscription r-fade-in-animation">
      <div class="r-primary-color headline">{{ 'home.newsletter.subscribe.headline' | translate }}</div>

      <div id="subscription-script-container">
        <script id="n2g_script">
          !(function (e, t, n, c, r, a, i) {
            (e.Newsletter2GoTrackingObject = r),
              (e[r] =
                e[r] ||
                function () {
                  (e[r].q = e[r].q || []).push(arguments);
                }),
              (e[r].l = 1 * new Date()),
              (a = t.createElement(n)),
              (i = t.getElementsByTagName(n)[0]),
              (a.async = 1),
              (a.src = c),
              i.parentNode.insertBefore(a, i);
          })(window, document, 'script', 'https://static.newsletter2go.com/utils.js', 'n2g');
        </script>
      </div>
    </div>
  </div> -->
