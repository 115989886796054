import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { SharedUiModule } from '@ranked/shared-ui';
import { LoadingSpinnerInlineComponent } from './components/loading-spinner-inline/loading-spinner-inline.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import * as fromUserFeedback from './state/user-feedback.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserFeedback.USERFEEDBACK_FEATURE_KEY, fromUserFeedback.userFeedbackReducer),
    SharedUiModule,
    // TranslateModule,
  ],
  declarations: [LoadingSpinnerComponent, MessageDialogComponent, LoadingSpinnerInlineComponent],
  exports: [LoadingSpinnerComponent, MessageDialogComponent, LoadingSpinnerInlineComponent],
})
export class UserFeedbackModule {}
