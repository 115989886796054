<ranked-header></ranked-header>

<div [ngSwitch]="verificationStatus$ | async" class="verification">
  <div *ngSwitchCase="'PENDING'" class="pending">
    <ranked-loading-spinner-inline></ranked-loading-spinner-inline>
    <div class="title">{{ 'verification.pending.text' | translate }}</div>
  </div>

  <div *ngSwitchCase="'SUCCESS'" class="success">
    <img class="icon" src="/assets/images/verification_success.svg" />
    <div class="title">{{ 'verification.success.title' | translate }}</div>
    <div class="text">{{ 'verification.success.text' | translate }}</div>
  </div>

  <div *ngSwitchCase="'ERROR'" class="error">
    <img class="icon" src="/assets/images/verification_error.svg" />
    <div class="title">{{ 'verification.error.title' | translate }}</div>
    <div class="text">{{ 'verification.error.text' | translate }} <a href="mailto:info@get-ranked.app">info@get-ranked.app</a></div>
  </div>
</div>
