<h2 class="r-headline r-primary-color">{{ 'home.team.title' | translate }}</h2>

<div class="team-members">
  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/timo.jpg" alt="Timo" />
      <span class="member-name">Timo</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/olli.jpg" alt="Oliver" />
      <span class="member-name">Oliver</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/jonas.jpg" alt="Jonas" />
      <span class="member-name">Jonas</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/patrick.jpg" alt="Patrick" />
      <span class="member-name">Patrick</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/christian.jpg" alt="Christian" />
      <span class="member-name">Christian</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/jan.jpg" alt="Jan" />
      <span class="member-name">Jan</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/simon.jpg" alt="Simon" />
      <span class="member-name">Simon</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/paul.jpg" alt="Paul" />
      <span class="member-name">Paul</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/christoph.jpg" alt="Christoph" />
      <span class="member-name">Christoph</span>
    </div>
  </ranked-hexagon>

  <ranked-hexagon class="team-member" [showShadow]="true" borderColor="custom">
    <div class="image-container">
      <img src="assets/images/team/jens.jpg" alt="Jens" />
      <span class="member-name">Jens</span>
    </div>
  </ranked-hexagon>
</div>

<p class="r-fade-in-animation">{{ 'home.team.description' | translate }}</p>
