export enum MessageType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  FEEDBACK = 'FEEDBACK',
}

export interface MessageOption<T> {
  value: T;
  label: string;
}

interface MessageBase<T> {
  id: string;
  type: MessageType;
  text: string;
  options: MessageOption<T>[];
}

interface SimpleMessage<T> extends MessageBase<T> {
  type: MessageType.ERROR | MessageType.INFO;
  options: [MessageOption<T>];
}

interface FeedbackMessage<T> extends MessageBase<T> {
  type: MessageType.FEEDBACK;
}

export type Message<T> = SimpleMessage<T> | FeedbackMessage<T>;
