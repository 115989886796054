import { Directive, ViewContainerRef } from '@angular/core';
import { OverlayHandlerService } from '../services/overlay-handler.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[rankedOverlayPlaceholder]',
})
export class OverlayPlaceholderDirective {
  constructor(viewContainerRef: ViewContainerRef, overlayService: OverlayHandlerService) {
    overlayService.setHost(viewContainerRef);
  }
}
