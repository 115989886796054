<div class="r-headline r-primary-color title">{{ 'home.features.title' | translate }}</div>

<div class="wrapper">
  <div class="feature r-fade-in-animation" id="profile_text">
    <img class="icon" src="assets/images/icon_statistics.svg" />
    <div class="r-primary-color headline">{{ 'home.features.statistics.headline' | translate }}</div>
    <div class="description">{{ 'home.features.statistics.description' | translate }}</div>
  </div>

  <div class="screenshot" id="profile_screenshot">
    <img class="localized" baseSrc="assets/images/screenshots/Statistics/Profile.png" src="" />
  </div>

  <div class="screenshot" id="ranking_screenshot">
    <img class="localized" baseSrc="assets/images/screenshots/Statistics/Leaderboard.png" src="" />
  </div>

  <div class="feature r-fade-in-animation" id="ranking_text">
    <img class="icon" src="assets/images/icon_ranking.svg" />
    <div class="r-primary-color headline">{{ 'home.features.ranking.headline' | translate }}</div>
    <div class="description">{{ 'home.features.ranking.description' | translate }}</div>
  </div>

  <div class="feature r-fade-in-animation" id="group_text">
    <img class="icon" src="assets/images/icon_group.svg" />
    <div class="r-primary-color headline">{{ 'home.features.group.headline' | translate }}</div>
    <div class="description">{{ 'home.features.group.description' | translate }}</div>
  </div>

  <div class="screenshot" id="group_screenshot">
    <img class="localized" baseSrc="assets/images/screenshots/Statistics/Notifications.png" src="" />
  </div>
</div>
