<ng-container *ngIf="roomInfo$ | async as roomInfo">
  <div class="room-name">{{ roomInfo.name }}</div>
  <div class="description">{{ 'copy-room-id.description' | translate }}</div>
  <div class="room-id">{{ roomInfo.id }}</div>

  <div class="button-container">
    <div class="hint" [class.hidden]="hint === null">{{ 'copy-room-id.hint.' + hint | translate }}</div>
    <button
      ranked-button
      [class.hidden]="hint !== null"
      text="{{ 'copy-room-id.button.copy' | translate }}"
      (click)="onCopyClicked(roomInfo.id)"
    ></button>
  </div>
</ng-container>
