<div *ngIf="message$ | async as message" class="dialog" [class.simple]="message.options.length === 1">
  <div class="content">
    <span class="message">{{ message.text }}</span>
    <button
      *ngFor="let option of message.options"
      ranked-button
      variant="flat"
      [fullWidth]="message.options.length === 1"
      text="{{ option.label }}"
      (click)="closeDialog(message.id, option.value)"
    ></button>
  </div>
</div>
