import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ENVIRONMENT_TOKEN } from '@ranked/settings';
import { SharedUiModule } from '@ranked/shared-ui';
import { UserFeedbackModule } from '@ranked/user-feedback';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CopyRoomIdComponent } from './components/copy-room-id/copy-room-id.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeDemoComponent } from './components/home-demo/home-demo.component';
import { HomeFeaturesComponent } from './components/home-features/home-features.component';
import { HomeNewsletterComponent } from './components/home-newsletter/home-newsletter.component';
import { HomeTeamComponent } from './components/home-team/home-team.component';
import { HomeWhyComponent } from './components/home-why/home-why.component';
import { HomeComponent } from './components/home/home.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ImprintComponent,
    PrivacyComponent,
    EmailVerificationComponent,
    CopyRoomIdComponent,
    HomeFeaturesComponent,
    HomeDemoComponent,
    HomeTeamComponent,
    HomeWhyComponent,
    HomeNewsletterComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    SharedUiModule,
    UserFeedbackModule,
  ],
  providers: [{ provide: ENVIRONMENT_TOKEN, useValue: environment }],
  bootstrap: [AppComponent],
})
export class AppModule {}
