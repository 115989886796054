import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: '[ranked-shutter], ranked-shutter',
  templateUrl: './shutter.component.html',
  styleUrls: ['./shutter.component.scss'],
})
export class ShutterComponent {
  @Input() @HostBinding('class.closed') public isClosed: boolean;
  @Input() @HostBinding('class') public position: 'top' | 'left' | 'right' = 'top';
  @Input() public clipText: string;

  @Output() public opened = new EventEmitter<void>();
  @Output() public closed = new EventEmitter<void>();

  constructor() {
    this.isClosed = true;
  }

  private open(): void {
    this.isClosed = false;
    this.opened.emit();
  }

  public toggle(): void {
    this.isClosed ? this.open() : this.close();
  }

  public close(): void {
    this.isClosed = true;
    this.closed.emit();
  }
}
