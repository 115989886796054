<div class="overlay-background" (click)="close()"></div>

<div class="panel">
  <ng-content></ng-content>
</div>

<button class="clip" (click)="toggle()">
  <div class="display">
    <span class="text">{{ clipText }}</span>
  </div>
</button>
