import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface RoomInfo {
  id: string;
  name: string;
}

@Component({
  selector: 'ranked-copy-room-id',
  templateUrl: './copy-room-id.component.html',
  styleUrls: ['./copy-room-id.component.scss'],
})
export class CopyRoomIdComponent implements OnInit {
  private readonly ROOM_NAME_PARAM = 'name';
  private readonly ROOM_ID_PARAM = 'id';

  public roomInfo$: Observable<RoomInfo>;
  public hint: string | null = null;

  constructor(private activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.roomInfo$ = this.activatedRoute.queryParamMap.pipe(
      map((params) => ({ id: params.get(this.ROOM_ID_PARAM), name: params.get(this.ROOM_NAME_PARAM) })),
    );
  }

  public onCopyClicked(id: string): void {
    this.hint = null;

    try {
      navigator.clipboard.writeText(id).then(
        () => (this.hint = 'success'),
        () => (this.hint = 'failed'),
      );
    } catch (e) {
      this.hint = 'failed';
    }
  }
}
