import { Directive, TemplateRef, Input } from '@angular/core';
import { OverlayHandlerService } from '../services/overlay-handler.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[rankedOverlay]',
})
export class OverlayDirective {
  private shown = false;

  @Input() public set rankedOverlay(show: boolean) {
    if (show !== this.shown) {
      this.shown = show;

      if (show) {
        this.overlayService.setTemplate(this.templateRef);
      } else {
        this.overlayService.removeTemplate();
      }
    }
  }

  constructor(private templateRef: TemplateRef<never>, private overlayService: OverlayHandlerService) {}
}
