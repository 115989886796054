import { Action, createReducer, on } from '@ngrx/store';
import { DeviceIdGenerated, DeviceIdLoaded } from './settings.actions';

export const SETTINGS_FEATURE_KEY = 'settings';

export interface SettingsState {
  deviceId?: string;
}

export interface SettingsAppState {
  readonly [SETTINGS_FEATURE_KEY]: SettingsState;
}

export const initialSettingsState: SettingsState = {};

const reducer = createReducer(
  initialSettingsState,
  on(DeviceIdGenerated, DeviceIdLoaded, (state, { deviceId }) => ({
    ...state,
    deviceId,
  })),
);

export function settingsReducer(state: SettingsState | undefined, action: Action): SettingsState {
  return reducer(state, action);
}
