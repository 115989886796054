import { Action, createReducer, on } from '@ngrx/store';
import { Message } from '../models/message';
import {
  CloseMessageDialog,
  DisableLoadingSpinner,
  EnableLoadingSpinner,
  RemoveMessageOutcome,
  ShowMessageDialog,
} from './user-feedback.actions';

export const USERFEEDBACK_FEATURE_KEY = 'userFeedback';

export interface UserFeedbackState {
  messages: Message<unknown>[];
  messageOutcomes: Record<string, unknown>;
  loadingOrigins: string[];
}

export interface UserFeedbackAppState {
  readonly [USERFEEDBACK_FEATURE_KEY]: UserFeedbackState;
}

export const initialUserFeedbackState: UserFeedbackState = {
  messages: [],
  messageOutcomes: {},
  loadingOrigins: [],
};

const removeOutecome = (messageOutcomes: Record<string, unknown>, id: string): Record<string, unknown> => {
  const outcomes = { ...messageOutcomes };
  delete outcomes[id];

  return outcomes;
};

const reducer = createReducer(
  initialUserFeedbackState,
  on(EnableLoadingSpinner, (state, { origin }) => ({
    ...state,
    loadingOrigins: [...state.loadingOrigins, origin],
  })),
  on(DisableLoadingSpinner, (state, { origin }) => ({
    ...state,
    loadingOrigins: state.loadingOrigins.filter((loadingOrigin) => loadingOrigin !== origin),
  })),
  on(ShowMessageDialog, (state, { message }) => ({
    ...state,
    messages: [...state.messages, message],
  })),
  on(CloseMessageDialog, (state, { id, outcome }) => ({
    ...state,
    messages: state.messages.filter((message) => message.id !== id),
    messageOutcomes: { ...state.messageOutcomes, [id]: outcome },
  })),
  on(RemoveMessageOutcome, (state, { id }) => ({
    ...state,
    messageOutcomes: removeOutecome(state.messageOutcomes, id),
  })),
);

export function userFeedbackReducer(state: UserFeedbackState | undefined, action: Action): UserFeedbackState {
  return reducer(state, action);
}
