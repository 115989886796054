<div class="background"></div>

<ng-content></ng-content>

<div class="icons">
  <button *ngIf="showClearButton" type="button" tabindex="-1" class="icon material-icons" (click)="onClear()">clear</button>
  <button *ngIf="showToggleSecureButton" type="button" tabindex="-1" class="icon material-icons" (click)="onToggleSecureInput()">
    {{ isSecure ? 'visibility' : 'visibility_off' }}
  </button>
</div>
