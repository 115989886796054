import { createAction, props } from '@ngrx/store';
import { Message } from '../models/message';

export const EnableLoadingSpinner = createAction('[UserFeedback/Interface] Enable Loading Spinner', props<{ origin: string }>());

export const DisableLoadingSpinner = createAction('[UserFeedback/Interface] Disable Loading Spinner', props<{ origin: string }>());

export const ShowMessageDialog = createAction('[UserFeedback/Feedback API] Show Message Dialog', props<{ message: Message<unknown> }>());

export const CloseMessageDialog = createAction(
  '[UserFeedback/Message Dialog] Close Message Dialog',
  props<{ id: string; outcome: unknown }>(),
);

export const RemoveMessageOutcome = createAction('[UserFeedback/Feedback API] Remove Message Outcome', props<{ id: string }>());
