import { Component, Input } from '@angular/core';

@Component({
  selector: 'ranked-list-divider',
  templateUrl: './list-divider.component.html',
  styleUrls: ['./list-divider.component.scss'],
})
export class ListDividerComponent {
  @Input()
  text = '';
}
