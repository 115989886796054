import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Message } from '../../models/message';
import { UserFeedbackStoreService } from '../../services/user-feedback-store.service';

@Component({
  selector: 'ranked-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent {
  public message$: Observable<Message<unknown>>;

  constructor(private userFeedbackStoreService: UserFeedbackStoreService) {
    this.message$ = userFeedbackStoreService.getRecentMessage();
  }

  public closeDialog(id: string, outcome: unknown): void {
    this.userFeedbackStoreService.closeMessageDialog({ id, outcome });
  }
}
