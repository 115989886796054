import { Component, Input } from '@angular/core';
import { NamedIcon } from '../../model';

@Component({
  selector: 'ranked-named-icon',
  templateUrl: './named-icon.component.html',
  styleUrls: ['./named-icon.component.scss'],
})
export class NamedIconComponent {
  @Input() public data: NamedIcon;
  @Input() public namePosition: 'bottom' | 'right';
  @Input() public iconColor: 'inverse' | 'default' = 'default';

  public onImageError(): void {
    this.data.iconPath = undefined;

    if (!this.data.iconName) {
      this.data.iconName = 'broken_image';
    }
  }
}
