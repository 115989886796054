import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserFeedbackStoreService } from '../../services/user-feedback-store.service';

@Component({
  selector: 'ranked-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  public isVisible$: Observable<boolean>;

  constructor(userFeedbackStoreService: UserFeedbackStoreService) {
    this.isVisible$ = userFeedbackStoreService.getDisplayLoadingSpinner();
  }
}
