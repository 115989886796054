import { Component } from '@angular/core';

@Component({
  selector: 'ranked-home-newsletter',
  templateUrl: './home-newsletter.component.html',
  styleUrls: ['./home-newsletter.component.scss'],
})
export class HomeNewsletterComponent {
  public readonly privacyCheckboxId = `privacy-${Math.floor(Math.random() * 100000)}`;
  public email = '';
  public privacyPolicyAccepted = false;
}
