import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'ranked-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(translateService: TranslateService, languageService: LanguageService) {
    translateService.setDefaultLang(languageService.defaultLang);
    translateService.use(languageService.currentLang);
  }
}
