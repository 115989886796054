<div class="container">
  <div class="ranked-button {{ variant }} {{ color }} size-{{ size }}" [class.with-icon]="showIcon" [class.box-shadow]="useBoxShadow">
    <div class="button-container">
      <div class="text">{{ text }}</div>
    </div>
    <div class="icon-container">
      <div class="icon-background">
        <ng-content select=".icon"></ng-content>
      </div>
    </div>
  </div>
</div>
