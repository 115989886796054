import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OverlayHandlerService {
  private host: ViewContainerRef;

  public setHost(host: ViewContainerRef): void {
    this.host = host;
  }

  public setTemplate(template: TemplateRef<unknown>): void {
    setTimeout(() => {
      this.host.clear();
      this.host.createEmbeddedView(template);
    });
  }

  public removeTemplate(): void {
    setTimeout(() => {
      this.host.clear();
    });
  }
}
