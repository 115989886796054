import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ranked-button, button[ranked-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() public variant: 'normal' | 'flat' | 'simple' = 'normal';
  @Input() public color: 'primary' | 'secondary' = 'primary';
  @Input() public size: 'small' | 'normal' | 'big' | 'huge' = 'normal';
  @Input() public text: string;
  @Input() public showIcon = false;
  @Input() public useBoxShadow = false;

  @Input()
  @HostBinding('class.full-width')
  public fullWidth = false;
}
