import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ListGroup, ListElement } from '../../model';

@Component({
  selector: 'ranked-sorted-list',
  templateUrl: './sorted-list.component.html',
  styleUrls: ['./sorted-list.component.scss'],
})
export class SortedListComponent implements OnChanges {
  public groups: Array<ListGroup>;

  @Input() public elements: Array<ListElement>;
  @Input() public grouping: (icon: ListElement) => string;
  @Input() public sorting?: (icon1: ListElement, icon2: ListElement) => number;
  @Input() public sortingGroups?: (group1: ListGroup, group2: ListGroup) => number;
  @Output() public selectElement: EventEmitter<ListElement>;

  constructor() {
    this.selectElement = new EventEmitter<ListElement>();
    this.groups = [];
    this.sorting = (icon1: ListElement, icon2: ListElement) => icon1.name.localeCompare(icon2.name, undefined, { sensitivity: 'accent' });
    this.sortingGroups = (group1: ListGroup, group2: ListGroup) =>
      group1.name.localeCompare(group2.name, undefined, { sensitivity: 'accent' });
  }

  private mapElementForDisplay(element: ListElement): ListElement {
    return {
      ...element,
      additionalClasses: typeof element.additionalClasses === 'string' ? element.additionalClasses : element.additionalClasses?.join(' '),
    };
  }

  private calculateGroups(): void {
    if (!this.grouping || !this.elements) {
      this.groups = [];
    } else {
      const groupsMap: { [key: string]: ListGroup } = {};
      this.elements.forEach((element) => {
        const key = this.grouping(element);
        if (!groupsMap[key]) {
          groupsMap[key] = { name: key, elements: [] };
        }
        groupsMap[key].elements.push(element);
      });

      const groups = Object.values(groupsMap).sort(this.sortingGroups);
      groups.forEach((group) => (group.elements = group.elements.map(this.mapElementForDisplay).sort(this.sorting)));

      this.groups = groups;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['elements'] || changes['grouping'] || changes['sorting']) {
      this.calculateGroups();
    }
  }

  public onElementClicked(element: ListElement): void {
    this.selectElement.emit(element);
  }
}
