import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ranked-loading-spinner-inline',
  templateUrl: './loading-spinner-inline.component.html',
  styleUrls: ['./loading-spinner-inline.component.scss'],
})
export class LoadingSpinnerInlineComponent {
  @HostBinding('class')
  public hostClass = 'loading-spinner';
}
