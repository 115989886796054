<div>
  <span>Ranked by </span>
  <a href="https://holisticon.de/" target="_blank">
    <img src="assets/images/holisticon-logo.png" />
  </a>
</div>
<div>
  <a routerLink="/privacy">{{ 'general.footer.privacy' | translate }}</a>
  <span class="separator">|</span>
  <a routerLink="/imprint">{{ 'general.footer.legal-notice' | translate }}</a>
</div>
