<h2 class="r-headline">{{ 'home.demo.title' | translate }}</h2>

<p class="r-fade-in-animation">{{ 'home.demo.usage' | translate }}</p>

<video controls preload="metadata" muted playsinline>
  <source src="assets/videos/how-it-works.webm" type="video/webm" />
  <source src="assets/videos/how-it-works.mp4" type="video/mp4" />
</video>

<p class="r-fade-in-animation">{{ 'home.demo.functionality' | translate }}</p>
