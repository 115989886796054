import { AfterViewInit, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'ranked-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
  public smallHeader$ = new BehaviorSubject(false);

  constructor(private localizationService: LocalizationService) {}

  private createAnimationsOnScrolling(): void {
    if ('IntersectionObserver' in window) {
      const triggerFadeInAnimation: IntersectionObserverCallback = (entries) => {
        entries
          .filter((entry) => entry.isIntersecting)
          .filter((entry) => !entry.target.classList.contains('r-animated'))
          .forEach((entry) => entry.target.classList.add('r-animated'));
      };

      const fadeInObserver = new IntersectionObserver(triggerFadeInAnimation, { threshold: 0.5 });

      document.querySelectorAll('.r-fade-in-animation').forEach((element) => {
        fadeInObserver.observe(element);
      });

      const titleElement = document.querySelector('.title-text');
      if (titleElement !== null) {
        const triggerHeaderFontSizeAnimation: IntersectionObserverCallback = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // header is not sticky
              this.smallHeader$.next(false);
            } else if (!this.smallHeader$.value) {
              this.smallHeader$.next(true);
            }
          });
        };
        const headerObserver = new IntersectionObserver(triggerHeaderFontSizeAnimation);
        const splashElement = document.querySelector('.splash');
        if (splashElement !== null) {
          headerObserver.observe(splashElement);
        }
      }

      const scrollIndicatorElement = document.querySelector('.scroll-indicator');
      if (scrollIndicatorElement !== null) {
        const triggerScrollIndicatorHideAnimation: IntersectionObserverCallback = (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              scrollIndicatorElement.classList.remove('hidden');
            } else if (!scrollIndicatorElement.classList.contains('hidden')) {
              // user scrolled down
              scrollIndicatorElement.classList.add('hidden');
            }
          });
        };
        const srollIndicatorObserver = new IntersectionObserver(triggerScrollIndicatorHideAnimation);
        const featuresElement = document.querySelector('.sections');
        if (featuresElement !== null) {
          srollIndicatorObserver.observe(featuresElement);
        }
      }
    } else {
      const animatedElements = document.querySelectorAll('.r-fade-in-animation');
      for (let i = 0; i < animatedElements.length; i++) {
        animatedElements[i].classList.add('r-animated');
      }
    }
  }

  public ngAfterViewInit(): void {
    this.localizationService.localizeImages();
    this.createAnimationsOnScrolling();
  }

  public scrollToContent(): void {
    const featuresElement = document.querySelector('.sections');
    if (featuresElement !== null) {
      const targetTop = Math.ceil(featuresElement.getBoundingClientRect().top - window.innerHeight * 0.1);
      const targetOffset = Math.ceil(targetTop / 40);
      const offsetIncrease = 2;
      const easeRange = (targetOffset * (targetOffset + 1)) / (2 * offsetIncrease);
      let offset = 0;

      const scrollInterval = setInterval(() => {
        window.scrollBy(0, offset);
        console.log(window.pageYOffset);

        if (window.pageYOffset + easeRange > targetTop + 1) {
          offset -= offsetIncrease;
        } else if (offset < targetOffset) {
          offset += offsetIncrease;
        }

        if (offset < 0) {
          clearInterval(scrollInterval);
        }
      }, 10);
    }
  }
}
