import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SettingsStoreService } from './services/settings-store.service';
import { SettingsEffects } from './state/settings.effects';
import { SETTINGS_FEATURE_KEY, settingsReducer } from './state/settings.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(SETTINGS_FEATURE_KEY, settingsReducer), EffectsModule.forFeature([SettingsEffects])],
  providers: [SettingsStoreService],
})
export class SettingsModule {}
