<h2 class="r-headline r-primary-color">{{ 'home.why.title' | translate }}</h2>

<p class="r-fade-in-animation">
  <span>{{ 'home.why.text.0' | translate }}</span>
  <span class="spacer"></span>
  <span>{{ 'home.why.text.1' | translate }}</span>
</p>

<div class="image-contaienr">
  <img src="assets/images/working_on_ranked.jpg" />
</div>

<p class="r-fade-in-animation">{{ 'home.why.text.2' | translate }}</p>
