import { Directive, ElementRef, HostListener } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: 'input[rankedInput]',
})
export class InputDirective {
  private validSubject = new BehaviorSubject(true);

  public get inputElement(): HTMLInputElement {
    return this.elementRef.nativeElement;
  }

  public get isValid$(): Observable<boolean> {
    return this.validSubject.asObservable().pipe(distinctUntilChanged());
  }

  @HostListener('change')
  public onInputChanged(): void {
    this.validSubject.next(this.isValid());
  }

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  private isValid(): boolean {
    const validity = this.inputElement.validity;
    if (this.inputElement.value.length > 0) {
      return validity && validity.valid;
    } else {
      return validity && !validity.valueMissing;
    }
  }
}
