import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { HexagonComponent } from './components/hexagon/hexagon.component';
import { InputEnhancerComponent } from './components/input-enhancer/input-enhancer.component';
import { ListDividerComponent } from './components/list-divider/list-divider.component';
import { NamedIconComponent } from './components/named-icon/named-icon.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SafeAreaComponent } from './components/safe-area/safe-area.component';
import { ShutterComponent } from './components/shutter/shutter.component';
import { SortedListComponent } from './components/sorted-list/sorted-list.component';
import { InputDirective } from './directives/input.directive';
import { OverlayPlaceholderDirective } from './directives/overlay-placeholder.directive';
import { OverlayDirective } from './directives/overlay.directive';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [
    ButtonComponent,
    NamedIconComponent,
    NavbarComponent,
    OverlayDirective,
    OverlayPlaceholderDirective,
    ShutterComponent,
    SortedListComponent,
    HexagonComponent,
    ListDividerComponent,
    InputEnhancerComponent,
    InputDirective,
    CheckboxComponent,
    SafeAreaComponent,
  ],
  exports: [
    ButtonComponent,
    NamedIconComponent,
    NavbarComponent,
    OverlayDirective,
    OverlayPlaceholderDirective,
    ShutterComponent,
    SortedListComponent,
    HexagonComponent,
    ListDividerComponent,
    InputEnhancerComponent,
    InputDirective,
    CheckboxComponent,
    SafeAreaComponent,
  ],
})
export class SharedUiModule {}
