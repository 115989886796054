import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ranked-hexagon, [ranked-hexagon]',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss'],
})
export class HexagonComponent {
  @Input()
  public borderColor: 'light' | 'dark' | 'custom' = 'light';

  @Input()
  public backgroundColor: 'light' | 'dark' | 'custom' = 'light';

  @Input()
  @HostBinding('class.show-shadow')
  public showShadow = true;
}
