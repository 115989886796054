import { Component, ContentChild, HostBinding, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { InputDirective } from '../../directives/input.directive';

@Component({
  selector: 'ranked-input-enhancer',
  templateUrl: './input-enhancer.component.html',
  styleUrls: ['./input-enhancer.component.scss'],
})
export class InputEnhancerComponent {
  private inputChild: InputDirective;
  private inputIsValidSubscription: Subscription;

  public isSecure = true;
  @Input() public showToggleSecureButton = false;
  @Input() public showClearButton = false;

  @Input()
  @HostBinding('class.centered')
  public centered = false;

  @HostBinding('class.invalid')
  public isInvalid = false;

  @ContentChild(InputDirective)
  public set child(input: InputDirective) {
    this.inputChild = input;

    this.inputIsValidSubscription?.unsubscribe();
    this.inputIsValidSubscription = this.inputChild.isValid$.subscribe((isValid) => {
      this.isInvalid = !isValid;
    });
  }

  public onToggleSecureInput(): void {
    this.isSecure = !this.isSecure;

    if (this.inputChild) {
      this.inputChild.inputElement.type = this.isSecure ? 'password' : 'text';
      this.inputChild.inputElement.focus();
    }
  }

  public onClear(): void {
    if (this.inputChild) {
      this.inputChild.inputElement.value = '';
      this.inputChild.inputElement.focus();
      this.inputChild.onInputChanged();
    }
  }
}
