import { Injectable } from '@angular/core';

export interface Language {
  token: string;
  name: string;
}

type Lang = 'de' | 'en' | 'fr' | 'nl' | 'pl';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly STORAGE_KEY = 'lang';
  private readonly SUPPORTED_LANGUAGES: Array<Lang> = ['de', 'en'];

  private readonly DEFAULT_LANGUAGE_TOKEN: Lang = 'en';
  private readonly LANGUAGE_TRANSLATIONS: { [L in Lang]: string } = {
    de: 'Deutsch',
    en: 'English',
    fr: 'Français',
    nl: 'Nederlands',
    pl: 'Polski',
  };

  private lang: Lang;

  /**
   * Returns the code of the current language
   */
  public get currentLang(): Lang {
    return this.lang;
  }

  /**
   * Returns the code of the default language
   */
  public get defaultLang(): Lang {
    return this.DEFAULT_LANGUAGE_TOKEN;
  }

  constructor() {
    this.lang = this.detectCurrentLanguage();
  }

  private detectCurrentLanguage(): Lang {
    const browserLanguageToken = navigator.language.split('-')[0] as Lang;
    let currentLanguage = localStorage.getItem(this.STORAGE_KEY) as Lang;

    if (!currentLanguage && this.SUPPORTED_LANGUAGES.indexOf(browserLanguageToken) >= 0) {
      currentLanguage = browserLanguageToken;
    }

    return currentLanguage || this.DEFAULT_LANGUAGE_TOKEN;
  }

  private translateLanguage(lang: Lang): string {
    return this.LANGUAGE_TRANSLATIONS[lang] || lang;
  }

  public availableLanguages(): Array<Language> {
    return this.SUPPORTED_LANGUAGES.map((token) => ({ token, name: this.translateLanguage(token) }));
  }

  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  public changeLanguage(languageToken: string): void {
    // TODO
  }

  /**
   * Returns the current langauge as a translated string
   */
  public currentLanguage(): string {
    return this.translateLanguage(this.lang);
  }
}
