import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { dispatch, select, StoreService } from '@ngxp/store-service';
import { filter, finalize, map, take } from 'rxjs/operators';
import * as uuid from 'uuid';
import { Message, MessageOption, MessageType } from '../models/message';
import {
  CloseMessageDialog,
  DisableLoadingSpinner,
  EnableLoadingSpinner,
  RemoveMessageOutcome,
  ShowMessageDialog,
} from '../state/user-feedback.actions';
import { UserFeedbackState } from '../state/user-feedback.reducer';
import { getDisplayLoadingSpinner, getMessageOutcomeById, getRecentMessage } from '../state/user-feedback.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserFeedbackStoreService extends StoreService<UserFeedbackState> {
  private showMessageDialog = dispatch(ShowMessageDialog);

  private removeMessageOutcome = dispatch(RemoveMessageOutcome);

  private getMessageOutcomeById = select(getMessageOutcomeById);

  public getDisplayLoadingSpinner = select(getDisplayLoadingSpinner);

  public getRecentMessage = select(getRecentMessage);

  public closeMessageDialog = dispatch(CloseMessageDialog);

  public enableLoadingSpinner = dispatch(EnableLoadingSpinner);

  public disableLoadingSpinner = dispatch(DisableLoadingSpinner);

  constructor(store: Store<UserFeedbackState>, actions: Actions, private translateService: TranslateService) {
    super(store, actions);
  }

  private createMessageId(): string {
    return uuid.v4();
  }

  private openMessageDialog<T>(message: Message<T>): Promise<T> {
    this.showMessageDialog({ message });

    return this.getMessageOutcomeById(message.id)
      .pipe(
        filter((outcome) => outcome !== undefined),
        take(1),
        finalize(() => {
          this.removeMessageOutcome({ id: message.id });
        }),
        map((outcome) => outcome as T),
      )
      .toPromise();
  }

  public async showErrorDialog(errorMessage: string, translate = false): Promise<void> {
    const message: Message<string> = {
      id: this.createMessageId(),
      type: MessageType.ERROR,
      text: translate ? this.translateService.instant(errorMessage) : errorMessage,
      options: [
        {
          label: this.translateService.instant('common.ok') as string,
          value: 'ok',
        },
      ],
    };

    await this.openMessageDialog(message);
  }

  public async showInfoDialog(messageText: string, translate = false): Promise<void> {
    const message: Message<string> = {
      id: this.createMessageId(),
      type: MessageType.INFO,
      text: translate ? this.translateService.instant(messageText) : messageText,
      options: [
        {
          label: this.translateService.instant('common.ok') as string,
          value: 'ok',
        },
      ],
    };

    await this.openMessageDialog(message);
  }

  public async showBooleanQuestionDialog(question: string, translate = false): Promise<boolean> {
    const message: Message<boolean> = {
      id: this.createMessageId(),
      type: MessageType.FEEDBACK,
      text: translate ? this.translateService.instant(question) : question,
      options: [
        {
          label: this.translateService.instant('user-feedback.message-dialog.button.yes') as string,
          value: true,
        },
        {
          label: this.translateService.instant('user-feedback.message-dialog.button.no') as string,
          value: false,
        },
      ],
    };

    return await this.openMessageDialog(message);
  }

  public async showFeedbackDialog<T>(text: string, options: MessageOption<T>[], translate = false): Promise<T> {
    const message: Message<T> = {
      id: this.createMessageId(),
      type: MessageType.FEEDBACK,
      text: translate ? this.translateService.instant(text) : text,
      options,
    };

    return await this.openMessageDialog(message);
  }
}
