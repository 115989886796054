import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ranked-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @HostBinding('class')
  public hostClass = 'r-primary-color r-blur-background';

  @HostBinding('class.small')
  @Input()
  public small = true;

  @Input()
  public linkToHome = true;
}
